<template>
    <div>
        <div class="card" v-if="(isParent.row.ap_usia <= 18 && isParent.row.ap_usia != null)">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Kajian Humpty Dumpty</h5>
            </div>
            <div class="card-body">
            <div class="form-group">
                <table class="table table-bordered table-hover pain-scale-table">
                <thead>
                    <tr>
                    <th>Pengkajian</th>
                    <th>Nilai 1</th>
                    <th>Nilai 2</th>
                    <th>Nilai 3</th>
                    <th>Nilai 4</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th class="table-warning text-center">Umur</th>
                    <td v-for="(v,k) in (Config.mr.hdUmur||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_umur" name="arankrj_humpty_umur" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Jenis Kelamin</th>
                    <td v-for="(v,k) in (Config.mr.hdJK||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_jenis_kelamin" name="arankrj_humpty_jenis_kelamin" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Diagnosis</th>
                    <td v-for="(v,k) in (Config.mr.hdDiagnosis||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_diagnosis" name="arankrj_humpty_diagnosis" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Gangguan Kognitif</th>
                    <td v-for="(v,k) in (Config.mr.hdGangguanKognitif||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_gangguan_kognitif" name="arankrj_humpty_gangguan_kognitif" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Faktor Lingkungan</th>
                    <td v-for="(v,k) in (Config.mr.hdFaktorLingkungan||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_faktor_lingkungan" name="arankrj_humpty_faktor_lingkungan" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Respon Pembedahan</th>
                    <td v-for="(v,k) in (Config.mr.hdPembedahan||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_respon_pembedahan" name="arankrj_humpty_respon_pembedahan" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Penggunaan Obat</th>
                    <td v-for="(v,k) in (Config.mr.hdPenggunaanObat||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_humpty_respon_penggunaan_obat" name="arankrj_humpty_respon_penggunaan_obat" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="table-info">
                    <td colspan="5" class="text-info-700">
                        <span class="text-uppercase">Tingkat Risiko Humpty Dumpty: {{hdTotal}}</span>
                        <span v-if="hdTotal < 7" class="border-left ml-2 pl-2 font-weight-semibold">Tidak Beresiko</span>
                        <span v-else-if="hdTotal >= 7 && hdTotal <= 11" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                        <span v-else-if="hdTotal >= 12" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>
                    </td>
                    <!-- Penilaian Tingkat Risiko Humpty Dumpty
                    7 - 11 = Risiko Rendah
                    >= 12 = Risiko Tinggi
                    -->
                    </tr>
                </tfoot>
                </table>
                <VValidate 
                    name="Humpty Dumpty Umur" 
                    v-model="isParent.row.arankrj_humpty_umur" 
                    :rules="toValidate(isParent.mrValidation.arankrj_humpty_umur)"
                    class="d-block"
                />

                
                <VValidate 
                    name="Humpty Dumpty Jenis Kelamin" 
                    v-model="isParent.row.arankrj_humpty_jenis_kelamin" 
                    :rules="toValidate(isParent.mrValidation.arankrj_humpty_jenis_kelamin)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Humpty Dumpty Diagnosis" 
                    v-model="isParent.row.arankrj_humpty_diagnosis" 
                    :rules="toValidate(isParent.mrValidation.arankrj_humpty_diagnosis)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Humpty Dumpty Gangguan Kognitif" 
                    v-model="isParent.row.arankrj_humpty_gangguan_kognitif" 
                    :rules="toValidate(isParent.mrValidation.arankrj_humpty_gangguan_kognitif)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Humpty Dumpty Faktor Lingkungan" 
                    v-model="isParent.row.arankrj_humpty_faktor_lingkungan" 
                    :rules="toValidate(isParent.mrValidation.arankrj_humpty_faktor_lingkungan)"
                    class="d-block"
                />

                <VValidate 
                    name="Humpty Dumpty Respon Pembedahan" 
                    v-model="isParent.row.arankrj_humpty_respon_pembedahan" 
                    :rules="toValidate(isParent.mrValidation.arankrj_humpty_respon_pembedahan)"
                    class="d-block"
                />

            </div>
            </div>
        </div>
        
        <div class="card" v-else-if="(isParent.row.ap_usia > 18 && isParent.row.ap_usia <= 60)">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Kajian Morse Fall Scale</h5>
            </div>
            <div class="card-body">
            <div class="form-group">
                <table class="table table-bordered table-hover pain-scale-table">
                <thead>
                    <tr>
                    <th>Pengkajian</th>
                    <th>Nilai 1</th>
                    <th>Nilai 2</th>
                    <th>Nilai 3</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th class="table-warning text-center">Riwayat Jatuh dalam 3 Bulan Terakhir</th>
                    <td v-for="(v,k) in (Config.mr.snRiwayatJatuh||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_morshe_riwayat_jatuh" name="arankrj_morshe_riwayat_jatuh" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Diagnosis Medis Sekunder > 1</th>
                    <td v-for="(v,k) in (Config.mr.snDiagnosa||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_morshe_diagnosa" name="arankrj_morshe_diagnosa" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Alat Bantu Jalan</th>
                    <td v-for="(v,k) in (Config.mr.snAlatBantuJalan||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_morshe_alat_bantu" name="arankrj_morshe_alat_bantu" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Pakai Infus</th>
                    <td v-for="(v,k) in (Config.mr.snPakaiInfus||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_morshe_inpus" name="arankrj_morshe_inpus" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Cara Berjalan / Berpindah</th>
                    <td v-for="(v,k) in (Config.mr.snCaraJalan||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_morshe_cara_jalan" name="arankrj_morshe_cara_jalan" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                    <tr>
                    <th class="table-warning text-center">Status Mental</th>
                    <td v-for="(v,k) in (Config.mr.snStatusMental||[])" :key="k">
                        <b-form-radio v-model="isParent.row.arankrj_morshe_status_mental" name="arankrj_morshe_status_mental" :value="v.value">{{v.text}}</b-form-radio>
                    </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="table-info">
                    <td colspan="5" class="text-info-700">
                        <span class="text-uppercase">Tingkat Risiko Morse Fall Scale: {{morsheTotal}}</span>
                        <span v-if="morsheTotal < 24" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                        <span v-else-if="morsheTotal >= 25 && morsheTotal <= 44" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                        <span v-else-if="morsheTotal > 45" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>

                    </td>
                    <!-- Penilaian Tingkat Risiko Humpty Dumpty
                    7 - 11 = Risiko Rendah
                    >= 12 = Risiko Tinggi
                    -->
                    </tr>
                </tfoot>
                </table>

                <VValidate 
                    name="Morshe Riwayat Jatuh" 
                    v-model="isParent.row.arankrj_morshe_riwayat_jatuh" 
                    :rules="toValidate(isParent.mrValidation.arankrj_morshe_riwayat_jatuh)"
                    class="d-block"
                />

                
                <VValidate 
                    name="Morshe Diagnosa" 
                    v-model="isParent.row.arankrj_morshe_diagnosa" 
                    :rules="toValidate(isParent.mrValidation.arankrj_morshe_diagnosa)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Morshe Alat Bantu" 
                    v-model="isParent.row.arankrj_morshe_alat_bantu" 
                    :rules="toValidate(isParent.mrValidation.arankrj_morshe_alat_bantu)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Morshe Inpus" 
                    v-model="isParent.row.arankrj_morshe_inpus" 
                    :rules="toValidate(isParent.mrValidation.arankrj_morshe_inpus)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Morshe Cara Jalan" 
                    v-model="isParent.row.arankrj_morshe_cara_jalan" 
                    :rules="toValidate(isParent.mrValidation.arankrj_morshe_cara_jalan)"
                    class="d-block"
                />

                <VValidate 
                    name="Morshe Status Mental" 
                    v-model="isParent.row.arankrj_morshe_status_mental" 
                    :rules="toValidate(isParent.mrValidation.arankrj_morshe_status_mental)"
                    class="d-block"
                />


                <div class="row mt-2">
                <div class="col-md-6">
                    <table class="table table-bordered table-striped table-hover table-sm patient-table">
                    <thead>
                        <tr>
                        <th>HASIL</th>
                        <th>TINDAKAN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Tidak Berisiko</td>
                        <td>Perawatan yang Baik</td>
                        </tr>
                        <tr>
                        <td>Risiko Rendah</td>
                        <td>Lakukan intervensi jatuh standar</td>
                        </tr>
                        <tr>
                        <td>Risiko Tinggi</td>
                        <td>Lakukan intervensi jatuh risiko tinggi</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                    <label for="">Catatan Tindakan </label>
                    <textarea name="" id="" rows="4" class="form-control"></textarea>
                    </div>
                </div> -->
                </div>
            </div>
            </div>
        </div>

        <div class="card" v-else-if="isParent.row.ap_usia > 60">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Kajian Skala Ontario Modified Stratify - Sydney Scoring</h5>
            </div>
            <div class="card-body">
            <div class="form-group">
                <label for="kidPainScale">Skala Risiko</label>
                <table class="table table-bordered">
                <tbody>
                    <tr class="table-warning">
                    <th colspan="2"><span class="font-weight-semibold">Riwayat Jatuh</span></th>
                    </tr>
                    <tr>
                    <td>Apakah pasien datang ke rumah sakit karena jatuh?</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_riwayat_jatuh_is_jatuh"
                        />
                        <VValidate 
                            name="Pasien Jatuh" 
                            v-model="isParent.row.arankrj_riwayat_jatuh_is_jatuh" 
                            :rules="toValidate(isParent.mrValidation.arankrj_riwayat_jatuh_is_jatuh)"
                        />
                    </td>
                    </tr>
                    <tr v-if="isParent.row.arankrj_riwayat_jatuh_is_jatuh == 'N'">
                    <td>Jika Tidak, apakah pasien mengalami jatuh dalam 2 bulan terakhir?</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_riwayat_jatuh_is_jatuh2bulan"
                        />
                        <VValidate 
                            name="Pasien Jatuh Dalam 2 Bulan" 
                            v-model="isParent.row.arankrj_riwayat_jatuh_is_jatuh2bulan" 
                            :rules="toValidate(isParent.mrValidation.arankrj_riwayat_jatuh_is_jatuh2bulan)"
                        />
                    </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th colspan="2"><span class="font-weight-semibold">Status Mental</span></th>
                    </tr>
                    <tr>
                    <td>Apakah pasien delirium? (tidak membuat keputusan, pola pikir tidak terorganisir, gangguan daya ingat)</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_status_mental_dellirium"
                        />
                        <VValidate 
                            name="Mental Dellirium" 
                            v-model="isParent.row.arankrj_status_mental_dellirium" 
                            :rules="toValidate(isParent.mrValidation.arankrj_status_mental_dellirium)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien disorientasi? (salah menyebutkan waktu, tempat atau orang)</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_status_mental_disorientasi"
                        />
                        <VValidate 
                            name="Mental Disorientasi" 
                            v-model="isParent.row.arankrj_status_mental_disorientasi" 
                            :rules="toValidate(isParent.mrValidation.arankrj_status_mental_disorientasi)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien mengalami agitasi? (ketakutan, gelisah dan cemas)</td>
                    <td>
                        
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_status_mental_agitasi"
                        />
                        <VValidate 
                            name="Mental Agitasi" 
                            v-model="isParent.row.arankrj_status_mental_agitasi" 
                            :rules="toValidate(isParent.mrValidation.arankrj_status_mental_agitasi)"
                        />
                    </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th colspan="2"><span class="font-weight-semibold">Penglihatan</span></th>
                    </tr>
                    <tr>
                    <td>Apakah memakai kacamata?</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_penglihatan_kacamata"
                        />
                        <VValidate 
                            name="Penglihatan Kacamata" 
                            v-model="isParent.row.arankrj_penglihatan_kacamata" 
                            :rules="toValidate(isParent.mrValidation.arankrj_penglihatan_kacamata)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien mengeluh penglihatan buram?</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_penglihatan_buram"
                        />
                        <VValidate 
                            name="Penglihatan Buram" 
                            v-model="isParent.row.arankrj_penglihatan_buram" 
                            :rules="toValidate(isParent.mrValidation.arankrj_penglihatan_buram)"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td>Apakah pasien mempunyai glaucoma, katarak atau degenerasi makula?</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_penglihatan_glaucoma"
                        />
                        <VValidate 
                            name="Penglihatan Glaucomma" 
                            v-model="isParent.row.arankrj_penglihatan_glaucoma" 
                            :rules="toValidate(isParent.mrValidation.arankrj_penglihatan_glaucoma)"
                        />
                    </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th colspan="2"><span class="font-weight-semibold">Kebiasaan</span></th>
                    </tr>
                    <tr>
                    <td>Apakah terdapat perubahan perilaku berkemih? (frekuensi, urgensi, inkontinensia, nokturia)</td>
                    <td>
                        <b-form-radio-group
                            
                            :options="isParent.Config.mr.yesNoOptV2"
                            v-model="isParent.row.arankrj_kebiasaan"
                        />
                        <VValidate 
                            name="Perilaku Berkemih" 
                            v-model="isParent.row.arankrj_kebiasaan" 
                            :rules="toValidate(isParent.mrValidation.arankrj_kebiasaan)"
                        />
                    </td>
                    </tr>

                    
                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th colspan="2"><span class="font-weight-semibold">Transfer (dari tempat tidur ke kursi dan kembali ke tempat tidur)</span></th>
                    </tr>
                    <tr v-for="(v,k) in (Config.mr.syTransfer||[])" :key="k">
                        <td colspan="2">
                            <b-form-radio v-model="isParent.row.arankrj_transfer" name="arankrj_transfer" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th colspan="2"><span class="font-weight-semibold">Mobilitas</span></th>
                    </tr>
                    <tr v-for="(v,k) in (Config.mr.syMobilitas||[])" :key="k">
                        <td colspan="2">
                            <b-form-radio v-model="isParent.row.arankrj_mobilitas" name="arankrj_mobilitas" :value="v.value">{{v.text}}</b-form-radio>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="table-info">
                    <td colspan="5" class="text-info-700">
                        <span class="text-uppercase">Tingkat Risiko Ontario Modified Stratify - Sydney Scoring: {{getTotalSydney}}</span>

                        <span v-if="getTotalSydney < 6" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Rendah</span>
                        <span v-else-if="getTotalSydney >= 6 && getTotalSydney < 17" class="border-left ml-2 pl-2 font-weight-semibold">Risiko Sedang</span>
                        <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Risiko Tinggi</span>

                    </td>
                    <!-- Penilaian Tingkat Risiko Ontario Modified Stratify - Sydney Scoring
                    0 - 5 = Risiko Rendah
                    6 - 16 = Risiko Sedang
                    17 - 30 = Risiko Tinggi
                    -->
                    </tr>
                </tfoot>
                </table>

                <VValidate 
                    name="Transfer" 
                    v-model="isParent.row.arankrj_transfer" 
                    :rules="toValidate(isParent.mrValidation.arankrj_transfer)"
                    class="d-block"
                />
                
                <VValidate 
                    name="Mobilitas" 
                    v-model="isParent.row.arankrj_mobilitas" 
                    :rules="toValidate(isParent.mrValidation.arankrj_mobilitas)"
                    class="d-block"
                />
            
                

            </div>
            </div>
        </div>
        
        
        <div class="card-body mt-2">
            <div class="row">
            <div class="col-md-12">
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                <thead>
                    <tr>
                    <th>HASIL</th>
                    <th>TINDAKAN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>
                        <span v-if="resikoIntervensiType == 'S'">Intervensi Resiko Jatuh Standar</span>
                        <span v-else-if="resikoIntervensiType == 'T'">Intervensi Resiko Jatuh Tinggi</span>
                        <span v-else>Tidak Beresiko</span>

                    </td>
                    <td>
                        <b-form-checkbox-group v-if="resikoIntervensiType == 'S'"
                            name="intervensi-resiko"
                            class="checkbox-block"
                            v-model="isParent.row.arankrj_data_intervensi"
                            :options="(Config.mr.ranapResikoJatuhSedang||[])"
                        ></b-form-checkbox-group>
                        <b-form-checkbox-group v-else-if="resikoIntervensiType == 'T'"
                            name="intervensi-resiko"
                            class="checkbox-block"
                            v-model="isParent.row.arankrj_data_intervensi"
                            :options="(Config.mr.ranapResikoJatuhTinggi||[])"
                        ></b-form-checkbox-group>
                        <b-form-checkbox-group v-else
                            name="intervensi-resiko"
                            class="checkbox-block"
                            v-model="isParent.row.arankrj_data_intervensi"
                            :options="(Config.mr.ranapResikoJatuhRendah||[])"
                        ></b-form-checkbox-group>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Jadwal Monitoring</h5>
            </div>
            <div class="card-body">
            <div>
                <table class="table table-bordered table-sm table-striped">
                <thead>
                    <tr>
                    <td>Jadwal</td>
                    <td>Tanggal dan Jam</td>
                    <td>Aksi</td>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(v,k) in (isParent.row.arankrj_jadwal_monitoring||[])" :key="k+'jm'">
                        <td>
                            Ke-{{k+1}}
                        </td>
                        <td>
                            <div class="input-group mb-3">
                            <datepicker :disabledDates="disabledDates" input-class="form-control transparent h-38" placeholder="Tanggal"
                                class="my-datepicker" calendar-class="my-datepicker_calendar"
                                v-model="isParent.row.arankrj_jadwal_monitoring[k]['date']">
                            </datepicker>
                            <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="isParent.row.arankrj_jadwal_monitoring[k]['jam']">
                            </vue-timepicker>
                            <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                            </div>
                            </div>
                            <VValidate 
                                :name="'Tanggal'+(k+1)" 
                                v-model="isParent.row.arankrj_jadwal_monitoring[k]['date']" 
                                :rules="{required : 1}"
                                class="d-block"
                            />
                            <VValidate :name="'Jam'+(k+1)" v-model="isParent.row.arankrj_jadwal_monitoring[k]['jam']"
                                :rules="{required : 1}" />
                        </td>

                        <td class="input-cell text-center">
                            <a href="javascript:;" @click="(isParent.row.arankrj_jadwal_monitoring||[]).splice(k,1)" data-popup="tooltip" title="Hapus" class="btn btn-sm btn-icon  text-danger-800 alpha-danger"><i class="icon-trash"></i></a>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                    <td colspan="5" class="text-info-700 text-center">
                        <a href="javascript:;" @click="addJadwal()" class="btn btn-sm alpha-info border-info"><i
                            class="icon-plus2 mr-1"></i>Tambah Jadwal</a>
                    </td>
                    </tr>
                </tfoot>
                </table>
            </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header bg-info">
            <h5 class="card-title font-weight-semibold">Checklist Pengaman</h5>
            </div>
            <div class="card-body">
            <div>
            
                <b-form-checkbox @change="checkAllPengaman($event)" v-model="isParent.row.arankrj_pengaman_check_all" value="Y"
                unchecked-value="N" class="form-check-input-styled p-0 ml-4">
                    Pilih Semua Item
                </b-form-checkbox>

                <table class="table table-bordered table-sm">
                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">TEMPAT TIDUR</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Pegangan sisi tempat tidur
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mudah dinaikkan dan diturunkan
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_naik_turun" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Terkunci dengan aman saat dinaikkan
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_aman_saat_naik" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Hanya digunakan untuk mobilitas
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_saat_mobilitas" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Roda
                    </td>
                    </tr>
                    <tr>
                    <td>
                        Mudah berputar/diarahkan, tidak melekat
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_roda" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Rem
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mengamankan tempat tidur saat dioperasikan
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_rem" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Mekanik
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Pengaturan ketinggian tempat tidur mudah dilakukan
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_mekanik" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Meja samping tempat tidur
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Roda terkunci dengan baik
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_meja_terkunci" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Letaknya disamping tempat tidur, menempel didinding
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bed_cek_meja_didinding" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">TIANG INFUS</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Tiang
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mudah dinaikkan dan diturunkan

                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_tiang_cek_naik_turun" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Stabil, tidak mudah goyang
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_tiang_cek_stabil" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Roda
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mudah berputar/diarahkan, tidak melekat
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_tiang_cek_berputar" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>


                </tbody>
                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">TUMPUAN KAKI</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary">
                    <td colspan="2">
                        Kaki kursi
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Proteksi karet anti-selip dikesemua kaki
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_tumpuan_proteksi" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Stabil, tidak goyang
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_tumpuan_stabil" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Bagian atas kursi
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Permukaan tidak licin
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_tumpuan_tidak_licin" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">BEL PANGGILAN/ PENCAHAYAAN</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Operasional
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Lampu diluar kamar
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bel_lampu_kamar" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Alarm berbunyi di counter
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bel_alarm" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Nomor kamar muncul di monitor
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bel_nomor_kamar" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Sinyal panel kamar
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bel_sinyal_kamar" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Akses
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mudah diraih saat di kamar mandi
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bel_mudah_diraih" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Dalam jangkauan saat pasien di tempat tidur
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_bel_tempat_tidur" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">WALKER/ CANE</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Keamanan
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Ujung karet pada alat berfungsi dengan baik
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_walker_ujung_karet" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Stabil
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_walker_stabil" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">TOILET BERJALAN</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Roda
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mudah berputar/diarahkan, tidak melekat
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_toilet_berputar" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Stabil saat pasien duduk diatasnya
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_toilet_stabil" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>


                    <tr class="table-secondary ">
                    <td colspan="2">
                        Rem
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mengamankan tempat tidur saat dioperasikan
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_rem" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                </tbody>

                <tbody>
                    <tr class="table-warning">
                    <th><span class="font-weight-semibold">KURSI BERODA (MOBILITY CHAIR)</span></th>
                    <th class="font-weight-semibold"><span class="font-weight-semibold">CHECKLIST</span></th>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Kursi
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Tingginya disesuaikan dengan pasien, untuk meminimalisir terjatuh/ terjungkal
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_tinggi_sesuai" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Roda
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mudah berputar/diarahkan, tidak melekat
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_roda_berputar" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>
                    <tr class="table-secondary ">
                    <td colspan="2">
                        Rem
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Dioperasikan saat kursi dalam posisi diam
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_rem_dioperasikan" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Mengamankan toilet saat dioperasikan
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_rem_aman" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Pengaman kursi
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_rem_pengaman" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Tumpuan kaki
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Dapat dilipat
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_kaki_dapat_dilipat" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Posisi
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Diposisikan dengan derajat kemiringan yang sesuai untuk mencegah terjungkal kedepan/
                        merosot
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_kaki_derajat_kemiringan" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                    <tr class="table-secondary ">
                    <td colspan="2">
                        Nampan
                    </td>
                    </tr>

                    <tr>
                    <td>
                        Dalam posisi aman
                    </td>
                    <td>
                        <div class="custom-control custom-control-inline custom-checkbox">
                            <b-form-checkbox v-model="isParent.row.arankrj_kursi_kaki_posisi_aman" value="Y"
                            unchecked-value="N" class="form-check-input-styled p-0">
                            </b-form-checkbox>
                        </div>
                    </td>
                    </tr>

                </tbody>

                </table>
            </div>
            </div>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default{
    extends: GlobalVue,
    components: {
      Datepicker,VueTimepicker
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },

        resikoIntervensiType(){
            if(this.isParent.row.ap_usia <= 18){
                // humpty dumpty
                if(this.hdTotal < 7){
                    return 'TB'
                }else if(this.hdTotal >= 7 && this.hdTotal <= 11){
                    return 'S'
                }else{
                    return 'T'
                }
            }else if(this.isParent.row.ap_usia > 18 && this.isParent.row.ap_usia <= 60){
                // morshe                
                if(!this.morsheTotal){
                    return 'TB'
                }else if(this.morsheTotal >= 1 && this.morsheTotal < 24){
                    return 'S'
                }else if(this.morsheTotal >= 25 && this.morsheTotal <= 44){
                    return 'S'
                }else{
                    return 'T'
                }
            }else if(this.isParent.row.ap_usia > 60){
                if(!this.getTotalSydney){
                    return 'TB'
                }else if(this.getTotalSydney >= 1 && this.getTotalSydney < 6){
                    return 'S'
                }else if(this.getTotalSydney >= 6 && this.getTotalSydney < 17){
                    return 'S'
                }else{
                    return 'T'
                }
            }else{
                return 'TB'
            } 
        },
        
        edTotal(){
            return (this.isParent.row.arankrj_ed_umur||0) + 
            (this.isParent.row.arankrj_ed_status_mental||0) + 
            ((this.isParent.row.arankrj_ed_pola_pikir == 11 ? (11+1): this.isParent.row.arankrj_ed_pola_pikir)||0) + 
            (this.isParent.row.arankrj_ed_pengobatan||0) + 
            (this.isParent.row.arankrj_ed_diagnosis||0) + 
            (this.isParent.row.arankrj_ed_ambulasi||0) + 
            (this.isParent.row.arankrj_ed_nutrisi||0) + 
            (this.isParent.row.arankrj_ed_riwayat_jatuh||0) 
        },

        hdTotal(){
            return (this.isParent.row.arankrj_humpty_umur||0) + 
            (this.isParent.row.arankrj_humpty_jenis_kelamin||0) + 
            (this.isParent.row.arankrj_humpty_diagnosis||0) + 
            (this.isParent.row.arankrj_humpty_gangguan_kognitif||0) + 
            (this.isParent.row.arankrj_humpty_faktor_lingkungan||0) + 
            (this.isParent.row.arankrj_humpty_respon_pembedahan||0) +
            (this.isParent.row.arankrj_humpty_respon_penggunaan_obat||0) 
        },

        morsheTotal(){
            return (this.isParent.row.arankrj_morshe_riwayat_jatuh||0) + 
            (this.isParent.row.arankrj_morshe_diagnosa||0) + 
            (this.isParent.row.arankrj_morshe_alat_bantu||0) + 
            (this.isParent.row.arankrj_morshe_inpus||0) + 
            (this.isParent.row.arankrj_morshe_cara_jalan||0) + 
            (this.isParent.row.arankrj_morshe_status_mental||0) 
        },

        
        getTotalSydney(){
            let total = 0
            
            if(this.isParent.row.arankrj_riwayat_jatuh_is_jatuh == "Y" || this.isParent.row.arankrj_riwayat_jatuh_is_jatuh2bulan == "Y"){
                total = total + 6
            }

            if(this.isParent.row.arankrj_status_mental_dellirium == "Y" || this.isParent.row.arankrj_status_mental_disorientasi == "Y" ||
            this.isParent.row.arankrj_status_mental_agitasi == "Y" ){
                total = total + 14
            }
            
            if(this.isParent.row.arankrj_penglihatan_kacamata == "Y" || this.isParent.row.arankrj_penglihatan_buram == "Y" ||
            this.isParent.row.arankrj_penglihatan_glaucoma == "Y" ){
                total = total + 1
            }

            if(this.isParent.row.arankrj_kebiasaan == "Y"){
                total = total + 2
            }

            if(this.isParent.row.arankrj_transfer + this.isParent.row.arankrj_mobilitas > 3){
                total = total + 7
            }
            return total  
        },

        isFillAllChecklistTindakan(){
            return this.isParent.row.arankrj_bed_cek_naik_turun == "Y" && this.isParent.row.arankrj_bed_cek_aman_saat_naik == "Y" && this.isParent.row.arankrj_bed_cek_saat_mobilitas == "Y" && this.isParent.row.arankrj_bed_cek_aman_saat_naik == "Y" && this.isParent.row.arankrj_bed_cek_saat_mobilitas == "Y" && this.isParent.row.arankrj_bed_cek_roda == "Y" && this.isParent.row.arankrj_bed_cek_rem == "Y" && this.isParent.row.arankrj_bed_cek_mekanik == "Y" && this.isParent.row.arankrj_bed_cek_meja_terkunci == "Y" && this.isParent.row.arankrj_bed_cek_meja_didinding == "Y" && this.isParent.row.arankrj_tiang_cek_naik_turun == "Y" && this.isParent.row.arankrj_tiang_cek_stabil == "Y" && this.isParent.row.arankrj_tiang_cek_berputar == "Y" && this.isParent.row.arankrj_tumpuan_proteksi == "Y" && this.isParent.row.arankrj_tumpuan_stabil == "Y" && this.isParent.row.arankrj_tumpuan_tidak_licin == "Y" && this.isParent.row.arankrj_bel_lampu_kamar == "Y" && this.isParent.row.arankrj_bel_alarm == "Y" && this.isParent.row.arankrj_bel_nomor_kamar == "Y" && this.isParent.row.arankrj_bel_sinyal_kamar == "Y" && this.isParent.row.arankrj_bel_mudah_diraih == "Y" && this.isParent.row.arankrj_bel_tempat_tidur == "Y" && this.isParent.row.arankrj_walker_ujung_karet == "Y" && this.isParent.row.arankrj_walker_stabil == "Y" && this.isParent.row.arankrj_toilet_berputar == "Y" && this.isParent.row.arankrj_toilet_stabil == "Y" && this.isParent.row.arankrj_rem == "Y" && this.isParent.row.arankrj_kursi_tinggi_sesuai == "Y" && this.isParent.row.arankrj_kursi_roda_berputar == "Y" && this.isParent.row.arankrj_kursi_rem_dioperasikan == "Y" && this.isParent.row.arankrj_kursi_rem_aman == "Y" && this.isParent.row.arankrj_kursi_rem_pengaman == "Y" && this.isParent.row.arankrj_kursi_kaki_dapat_dilipat == "Y" && this.isParent.row.arankrj_kursi_kaki_derajat_kemiringan == "Y" && this.isParent.row.arankrj_kursi_kaki_posisi_aman == "Y"
        },
    
    },
    methods: {
        checkAllPengaman(e){
            if(e == "Y"){
                this.isParent.row.arankrj_bed_cek_naik_turun = "Y"
                this.isParent.row.arankrj_bed_cek_aman_saat_naik = "Y"
                this.isParent.row.arankrj_bed_cek_saat_mobilitas = "Y"
                this.isParent.row.arankrj_bed_cek_roda = "Y"
                this.isParent.row.arankrj_bed_cek_rem = "Y"
                this.isParent.row.arankrj_bed_cek_mekanik = "Y"
                this.isParent.row.arankrj_bed_cek_meja_terkunci = "Y"
                this.isParent.row.arankrj_bed_cek_meja_didinding = "Y"
                this.isParent.row.arankrj_tiang_cek_naik_turun = "Y"
                this.isParent.row.arankrj_tiang_cek_stabil = "Y"
                this.isParent.row.arankrj_tiang_cek_berputar = "Y"
                this.isParent.row.arankrj_tumpuan_proteksi = "Y"
                this.isParent.row.arankrj_tumpuan_stabil = "Y"
                this.isParent.row.arankrj_tumpuan_tidak_licin = "Y"
                this.isParent.row.arankrj_bel_lampu_kamar = "Y"
                this.isParent.row.arankrj_bel_alarm = "Y"
                this.isParent.row.arankrj_bel_nomor_kamar = "Y"
                this.isParent.row.arankrj_bel_sinyal_kamar = "Y"
                this.isParent.row.arankrj_bel_mudah_diraih = "Y"
                this.isParent.row.arankrj_bel_tempat_tidur = "Y"
                this.isParent.row.arankrj_walker_ujung_karet = "Y"
                this.isParent.row.arankrj_walker_stabil = "Y"
                this.isParent.row.arankrj_toilet_berputar = "Y"
                this.isParent.row.arankrj_toilet_stabil = "Y"
                this.isParent.row.arankrj_rem = "Y"
                this.isParent.row.arankrj_kursi_tinggi_sesuai = "Y"
                this.isParent.row.arankrj_kursi_roda_berputar = "Y"
                this.isParent.row.arankrj_kursi_rem_dioperasikan = "Y"
                this.isParent.row.arankrj_kursi_rem_aman = "Y"
                this.isParent.row.arankrj_kursi_rem_pengaman = "Y"
                this.isParent.row.arankrj_kursi_kaki_dapat_dilipat = "Y"
                this.isParent.row.arankrj_kursi_kaki_derajat_kemiringan = "Y"
                this.isParent.row.arankrj_kursi_kaki_posisi_aman = "Y"
            }else{
                this.isParent.row.arankrj_bed_cek_naik_turun = "N"
                this.isParent.row.arankrj_bed_cek_aman_saat_naik = "N"
                this.isParent.row.arankrj_bed_cek_saat_mobilitas = "N"
                this.isParent.row.arankrj_bed_cek_roda = "N"
                this.isParent.row.arankrj_bed_cek_rem = "N"
                this.isParent.row.arankrj_bed_cek_mekanik = "N"
                this.isParent.row.arankrj_bed_cek_meja_terkunci = "N"
                this.isParent.row.arankrj_bed_cek_meja_didinding = "N"
                this.isParent.row.arankrj_tiang_cek_naik_turun = "N"
                this.isParent.row.arankrj_tiang_cek_stabil = "N"
                this.isParent.row.arankrj_tiang_cek_berputar = "N"
                this.isParent.row.arankrj_tumpuan_proteksi = "N"
                this.isParent.row.arankrj_tumpuan_stabil = "N"
                this.isParent.row.arankrj_tumpuan_tidak_licin = "N"
                this.isParent.row.arankrj_bel_lampu_kamar = "N"
                this.isParent.row.arankrj_bel_alarm = "N"
                this.isParent.row.arankrj_bel_nomor_kamar = "N"
                this.isParent.row.arankrj_bel_sinyal_kamar = "N"
                this.isParent.row.arankrj_bel_mudah_diraih = "N"
                this.isParent.row.arankrj_bel_tempat_tidur = "N"
                this.isParent.row.arankrj_walker_ujung_karet = "N"
                this.isParent.row.arankrj_walker_stabil = "N"
                this.isParent.row.arankrj_toilet_berputar = "N"
                this.isParent.row.arankrj_toilet_stabil = "N"
                this.isParent.row.arankrj_rem = "N"
                this.isParent.row.arankrj_kursi_tinggi_sesuai = "N"
                this.isParent.row.arankrj_kursi_roda_berputar = "N"
                this.isParent.row.arankrj_kursi_rem_dioperasikan = "N"
                this.isParent.row.arankrj_kursi_rem_aman = "N"
                this.isParent.row.arankrj_kursi_rem_pengaman = "N"
                this.isParent.row.arankrj_kursi_kaki_dapat_dilipat = "N"
                this.isParent.row.arankrj_kursi_kaki_derajat_kemiringan = "N"
                this.isParent.row.arankrj_kursi_kaki_posisi_aman = "N"
            }
        },
        toValidate(val){
            return {...val}
        },
        addJadwal(){
            this.isParent.row.arankrj_jadwal_monitoring.push({
                date : null,
                jam : ''
            })
        }
    },
    watch:{
        edTotal(v){
            setTimeout(()=>{
                this.isParent.row.arankrj_ed_value = v
            },250)
        },

        hdTotal(v){
            setTimeout(()=>{
                this.isParent.row.arankrj_humpty_value = v
            },250)
        },

        morsheTotal(v){
            setTimeout(()=>{
                this.isParent.row.arankrj_morshe_value = v
            },250)
        },
        
        resikoIntervensiType(v){
            setTimeout(()=>{
                this.isParent.row.arankrj_type_intervensi = v
            },250)
            
            if(v == 'S' || v == 'T'){
                let dataResiko = {}
                let adj = v == 'S' ? 24 : 8 
                let dateAfter = moment().add(adj, 'hours').format('YYYY-MM-DD')
                let timeAfter = moment().add(adj, 'hours').format('HH:mm')
                
                dataResiko = {
                    date : dateAfter,
                    jam : timeAfter
                }
                if(!(this.isParent.row.arankrj_jadwal_monitoring||[]).length){
                    this.isParent.row.arankrj_jadwal_monitoring.push(dataResiko)
                }else if((this.isParent.row.arankrj_jadwal_monitoring||[]).length == 1){
                    this.isParent.row.arankrj_jadwal_monitoring[0] = dataResiko                
                }
            }
        },
        
        getTotalSydney(v){
            setTimeout(()=>{
                this.isParent.row.arankrj_sydney_value = v
            },250)    
        },
        
        isFillAllChecklistTindakan(v){
            if(v){
                this.isParent.row.arankrj_pengaman_check_all = "Y"
            }else{
                this.isParent.row.arankrj_pengaman_check_all = "N"
            }
        }
    }

}
</script>
